export const authorTitles = [
  'Mr',
  'Miss',
  'Mrs',
  'Ms',
  'Mx',
  'Sir',
  'Mistress',
  'Madam',
  'Dr.',
  'Professor',
  'Chancellor',
  'Vice-Chancellor',
  'Principal',
  'President',
];

export const titleSuggestion = (titleInput: any) => {
  return authorTitles.filter((str) =>
    str.toLowerCase().includes(titleInput?.toLowerCase()),
  );
};
