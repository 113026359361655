





























































































import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop, Provide } from 'vue-property-decorator';
import StagingAuthorFormStepOne from './StagingAuthorFormStepOne.vue';
import StagingAuthorFormStepTwo from './StagingAuthorFormStepTwo.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import {
  Author,
  PendingDocumentRawAuthorData,
  StagingAuthorData
} from '@/jbi-shared/types/author.types';

@Component({
  components: {
    StagingAuthorFormStepOne,
    StagingAuthorFormStepTwo,
    ValidationObserver,
    ValidationProvider
  }
})
export default class StagingAuthorsForm extends mixins(ViewModeMixin) {
  @Prop()
  public existingAuthors!: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];
  @Prop()
  public validAuthors!: StagingAuthorData[];
  @Prop()
  public invalidAuthors!: StagingAuthorData[];

  public selectedAuthors: StagingAuthorData[] = [];
  public activeStep = 1;

  public nextStep() {
    if (this.activeStep < 2) {
      this.activeStep += 1;
    }
  }

  public prevStep() {
    if (this.activeStep > 1) {
      this.activeStep -= 1;
      this.resetSelectedAuthors();
    }
  }

  public resetSelectedAuthors() {
    this.selectedAuthors = [];
  }

  public saveAuthors() {
    const updatedAuthors = this.selectedAuthors.map((author) => {
      /* Remove the raw property from author when we're ready
         to save the updated author details. */
      if (author.content.hasOwnProperty('raw')) {
        const {
          raw,
          ...authorDetails
        } = author.content as PendingDocumentRawAuthorData;
        return {
          ...author,
          content: {
            ...authorDetails
          },
          isValid: true
        };
      }

      return {
        ...author,
        isValid: true
      };
    });
    this.$emit('update:authors', updatedAuthors);
    this.$emit('close');
  }
}
