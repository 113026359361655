























































import { Component, Prop } from 'vue-property-decorator';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { opsToText, textToOps } from '@/utils/quill-delta.util';
import { formatAuthors } from '@/jbi-shared/util/document.utils';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import StagingAuthorsForm from './StagingAuthorsForm.vue';
import { Author, StagingAuthorData } from '@/jbi-shared/types/author.types';
import SectionError from '@/components/editor/SectionEditor/SectionError.vue';
import {
  PendingDocumentError,
  PendingSectionError
} from '@/jbi-shared/types/document.types';

@Component({
  components: {
    SectionError,
    SectionTitleEditor,
    StagingAuthorsForm
  }
})
export default class AuthorSectionEditor extends mixins(ViewModeMixin) {
  @Prop(String)
  public title!: string;
  @Prop(Boolean)
  public disabled!: boolean;
  @Prop(Number)
  public authorSectionId!: number;
  @Prop()
  public dirtyAuthors!: StagingAuthorData[];
  @Prop()
  public existingAuthors!: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];

  private pendingAuthorError: PendingDocumentError[] = [];

  get sectionTitle() {
    return textToOps(this.title);
  }

  get hasInvalidAuthors() {
    let hasError = false;
    if (this.invalidAuthors.length > 0) {
      hasError = true;
      this.pendingAuthorError = [
        {
          code: PendingSectionError.SECTION_MISSING_INFO,
          message: `Missing information in ${this.title}`
        }
      ];
    }
    return hasError;
  }

  get invalidAuthors() {
    return this.dirtyAuthors.filter((author) => !author.isValid);
  }

  get invalidAuthorsToText(): string[] {
    return this.invalidAuthors.map((author: any) =>
      opsToText(author.content.raw)
    );
  }

  get validAuthors(): StagingAuthorData[] {
    return this.dirtyAuthors.filter((author) => author.isValid);
  }

  get validAuthorsToText(): string[] {
    return formatAuthors(
      this.validAuthors.map((author) => author.content as Author)
    );
  }

  public openEditModal() {
    this.$buefy.modal.open({
      parent: this,
      component: StagingAuthorsForm,
      hasModalCard: true,
      fullScreen: true,
      canCancel: true,
      trapFocus: true,
      props: {
        existingAuthors: this.existingAuthors,
        validAuthors: this.validAuthors,
        invalidAuthors: this.invalidAuthors
      },
      events: {
        'update:authors': (
          e: FullDocumentRevisionObject['revision']['sections']['authorSubSections']
        ) => this.$emit('update:authors', e)
      }
    });
  }
}
