










































































import {
  PendingDocumentRawAuthorData,
  StagingAuthorData
} from '@/jbi-shared/types/author.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { formatAuthorForDisplay } from '@/jbi-shared/util/document.utils';
import { opsToText } from '@/utils/quill-delta.util';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class StagingAuthorsFormStepOne extends mixins(ViewModeMixin) {
  @Prop()
  public existingAuthors!: FullDocumentRevisionObject['revision']['sections']['authorSubSections'];
  @Prop()
  public validAuthors!: StagingAuthorData[];
  @Prop()
  public invalidAuthors!: StagingAuthorData[];
  @Prop()
  public selectedAuthors!: StagingAuthorData[];

  public showInvalidAuthorError = false;

  get proxySelectedAuthors() {
    return this.selectedAuthors;
  }
  set proxySelectedAuthors(value) {
    this.$emit('update:selectedAuthors', value);
  }

  public formatAuthor(author: any) {
    return formatAuthorForDisplay(author.content);
  }

  public formatInvalidAuthor(invalidAuthor: StagingAuthorData) {
    return opsToText(
      (invalidAuthor.content as PendingDocumentRawAuthorData).raw
    );
  }
}
