













































import {
  PendingDocumentRawAuthorData,
  StagingAuthorData
} from '@/jbi-shared/types/author.types';
import { generateDraftAuthor } from '@/jbi-shared/util/document.utils';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import StagingAuthorFormCard from './StagingAuthorFormCard.vue';
import draggable from 'vuedraggable';

@Component({
  components: {
    draggable,
    StagingAuthorFormCard
  }
})
export default class StagingAuthorFormStepTwo extends mixins(ViewModeMixin) {
  @Prop()
  public selectedAuthors!: StagingAuthorData[];

  get proxySelectedAuthors() {
    return this.selectedAuthors;
  }

  set proxySelectedAuthors(value) {
    this.$emit('update:selectedAuthors', value);
  }

  public addNewAuthor() {
    const updatedAuthors = [
      ...this.proxySelectedAuthors,
      generateDraftAuthor(0)
    ];
    this.proxySelectedAuthors = updatedAuthors;
  }

  public removeAuthor(i: number) {
    const updatedAuthors = this.proxySelectedAuthors.filter((_, j) => j !== i);
    this.proxySelectedAuthors = updatedAuthors;
  }

  public tryToAutofillAuthorDetails() {
    const pendingRawAuthorData = this.selectedAuthors.filter(
      (author) => !!(author.content as PendingDocumentRawAuthorData).raw
    );

    if (!pendingRawAuthorData.length) {
      return;
    }

    const autofilledAuthorDetails: StagingAuthorData[] = [];

    pendingRawAuthorData.forEach((rawAuthor) => {
      const { raw } = rawAuthor.content as PendingDocumentRawAuthorData;
      const text = raw[0]?.insert;

      if (!(typeof text === 'string')) {
        return;
      }

      // Generate auto-filled author details from raw author text
      const autofilledAuthorDetail = {
        ...rawAuthor,
        content: {
          ...rawAuthor.content,
          ...this.generateAutofillAuthorDetailsFromRawText(text)
        }
      };

      autofilledAuthorDetails.push(autofilledAuthorDetail);
    });

    // Filter to get the authors that don't need to autofill
    let updatedAuthors = this.selectedAuthors.filter(
      (author) => !(author.content as PendingDocumentRawAuthorData).raw
    );

    updatedAuthors = [...updatedAuthors, ...autofilledAuthorDetails];
    this.proxySelectedAuthors = updatedAuthors;
  }

  public generateAutofillAuthorDetailsFromRawText(text: string) {
    /* A proper author detail should at least have 2 fields:
       1. First Name
       2. Last Name.
       3. Title (optional)
       4. Qualification (optional) */
    const splittedText = text.trim().split(' ');
    if (splittedText.length < 2) {
      return;
    }

    /* We assume that the first word is the first name.
       Clients requested that we do not try to autofill the
       title of the author. */
    const firstName = splittedText[0];
    const lastName = splittedText[1];
    let qualification = '';
    if (splittedText.length > 2) {
      qualification = splittedText.slice(2).join(' ');
    }

    return {
      title: '',
      firstName,
      lastName,
      qualification
    };
  }

  public mounted() {
    this.tryToAutofillAuthorDetails();
  }
}
