






































































import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { generateDraftAuthor } from '@/jbi-shared/util/document.utils';
import { opsToText } from '@/utils/quill-delta.util';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import {
  Author,
  PendingDocumentRawAuthorData,
  StagingAuthorData
} from '@/jbi-shared/types/author.types';
import { titleSuggestion } from '@/jbi-shared/util/cplus-author.utils';

@Component({
  components: {
    ValidationProvider
  }
})
export default class StagingAuthorsFormCard extends mixins(ViewModeMixin) {
  @Prop({
    default() {
      return generateDraftAuthor(0);
    }
  })
  public value!: StagingAuthorData;

  /*
   * There is a limitation in VeeValidate whereby we are forced to use
   * v-model in order for the ValidationProvider to attach correctly to the input field.
   * Because of that, we have to use this individual get/set pattern for each
   * form field because we cannot directly mutate the prop.
   */
  get authorTitle() {
    return (this.value.content as Author).title;
  }

  set authorTitle(value) {
    this.updateAuthorDetails(value, 'title');
  }

  get authorFirstName() {
    return (this.value.content as Author).firstName;
  }

  set authorFirstName(value) {
    this.updateAuthorDetails(value, 'firstName');
  }

  get authorLastName() {
    return (this.value.content as Author).lastName;
  }

  set authorLastName(value) {
    this.updateAuthorDetails(value, 'lastName');
  }

  get authorQualification() {
    return (this.value.content as Author).qualification;
  }

  set authorQualification(value) {
    this.updateAuthorDetails(value, 'qualification');
  }

  get isInvalidAuthor() {
    return this.value.hasOwnProperty('isValid') && this.value.isValid === false;
  }

  get invalidAuthorToText() {
    return opsToText((this.value.content as PendingDocumentRawAuthorData).raw);
  }

  get titleSuggestion() {
    return titleSuggestion((this.value.content as Author).title);
  }

  public updateAuthorDetails(newValue: string, key: string) {
    const updatedAuthor = {
      ...this.value,
      content: {
        ...this.value.content,
        [key]: newValue
      }
    };
    this.$emit('input', updatedAuthor);
  }
}
